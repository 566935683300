import React, { useState, useRef, useEffect, useContext } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper/modules";
import * as SettingSelector from "../../../store/setting/selectors";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import login1 from "../../../assets/images/login/photo-talking.jpg";
import login2 from "../../../assets/images/login/photo-talk.jpg";
import login3 from "../../../assets/images/login/photo-onovolab.png";
import logo from "../../../assets/images/memberfy-logo.png";
import { signIn } from '../../../utilities/api';
import AuthContext from '../../../contexts/AuthContext';

SwiperCore.use([Navigation, Autoplay]);

const SignIn = () => {
  const appName = useSelector(SettingSelector.app_name);
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [verificationCode, setVerificationCode] = useState(Array(6).fill(""));
  const [showVerification, setShowVerification] = useState(false);
  const inputRefs = useRef([]);
  const emailInputRef = useRef(null);

  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const context = useContext(AuthContext);

  useEffect(() => {
    if (!showVerification && emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, [showVerification]);

  useEffect(() => {
    if (showVerification && inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, [showVerification]);

  const handleSignIn = async () => {
    try {
      const signInResponse = await signIn(email);
      if (signInResponse.data.signIn.success) {
        setAccessToken(signInResponse.data.signIn.accessToken);
        setShowVerification(true);
      } else {
        alert("Login failed, please try again.");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      alert("An error occurred during login.");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission
      handleSignIn();
    }
  };

  const handleCodeChange = (index, value) => {
    if (/^\d$/.test(value) || value === "") {
      const newCode = [...verificationCode];
      newCode[index] = value;
      setVerificationCode(newCode);

      if (value !== "" && index < 5) {
        inputRefs.current[index + 1].focus();
      }

      if (value === "" && index > 0) {
        inputRefs.current[index - 1].focus();
        newCode[index] = "";
        setVerificationCode(newCode);
      }

      if (index === 5 && value !== "") {
        handleVerifyCode(newCode.join(""));
      }
    }
  };

  const handleVerifyCode = (code) => {
    // Add logic to verify the code here
    console.log("Verifying code:", code);
    if (code === "123456") { // exemplo de código de verificação
      login(accessToken);
      navigate('/');
    } else {
      alert("Invalid verification code.");
    }
  };

  const handlePaste = (event) => {
    const paste = event.clipboardData.getData('text').replace(/-/g, '');
    if (/^\d{6}$/.test(paste)) {
      const newCode = paste.split("");
      setVerificationCode(newCode);
      inputRefs.current[5].focus();
      handleVerifyCode(newCode.join(""));
    }
  };

  return (
    <>
      <section className="sign-in-page">
        <Container fluid>
          <Row className="align-items-center">
            <Col md={6} className="overflow-hidden position-relative">
              <div className="bg-primary w-100 h-100 position-absolute top-0 bottom-0 start-0 end-0"></div>
              <div className="container-inside z-1">
                <div className="main-circle circle-small"></div>
                <div className="main-circle circle-medium"></div>
                <div className="main-circle circle-large"></div>
                <div className="main-circle circle-xlarge"></div>
                <div className="main-circle circle-xxlarge"></div>
              </div>
              <div className="sign-in-detail container-inside-top">
                <Swiper
                  className="list-inline m-0 p-0 "
                  spaceBetween={10}
                  centeredSlides={true}
                  loop={true}
                  autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                  }}
                >
                  <ul className="swiper-wrapper list-inline m-0 p-0 ">
                    <SwiperSlide>
                      <img
                        src={login1}
                        className="signin-img img-fluid mb-5 rounded-3"
                        alt="images"
                      />
                      <h2 className="mb-3 text-white fw-semibold">
                        Reach the right people at the right time
                      </h2>
                      <p className="font-size-16 text-white mb-0">
                        Meet entrepreneurs, speakers and users, and build <br/>
                        lasting professional relationships.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={login2}
                        className="signin-img img-fluid mb-5 rounded-3"
                        alt="images"
                      />
                      <h2 className="mb-3 text-white fw-semibold">
                        Real Engagement in Real Time
                      </h2>
                      <p className="font-size-16 text-white mb-0">
                        Join hundreds of lively discussions, posts, and ideas shared <br/>
                        from our community members.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={login3}
                        className="signin-img img-fluid mb-5 rounded-3"
                        alt="images"
                      />
                      <h2 className="mb-3 text-white fw-semibold">
                      The modern way to build a community
                      </h2>
                      <p className="font-size-16 text-white mb-0">
                      Memberfy is the only community that puts community thinking <br/>
                      at the heart of the product.
                      </p>
                    </SwiperSlide>
                  </ul>
                </Swiper>
              </div>
            </Col>
            <Col md={6}>
              <div className="sign-in-from text-center">
                <Link
                  to="/"
                  className="d-inline-flex align-items-center justify-content-center gap-2">
                  <h2 className="logo-title" data-setting="app_name">
                    <img src={logo} className="img-fluid rounded-normal" width="250" alt="{appName}" />
                  </h2>
                </Link>
                <p className="mt-3 font-size-16" dangerouslySetInnerHTML={{ __html: t('Welcome to Memberfy') }}/>
                {!showVerification ? (
                  <Form className="mt-5">
                    <Form.Group className="form-group text-start">
                      <h6 className="form-label fw-bold">
                        Email Address
                      </h6>
                      <Form.Control
                        type="email"
                        className="form-control mb-0"
                        placeholder="Your Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={handleKeyDown}
                        ref={emailInputRef}
                      />
                    </Form.Group>
                    <Button
                      variant="primary"
                      type="button"
                      className="btn btn-primary mt-4 fw-semibold text-uppercase w-100"
                      onClick={handleSignIn}
                    >
                      Sign in
                    </Button>
                    <h6 className="mt-5">
                      Don't Have An Account ?{" "}
                      <Link to="/auth/sign-up">Sign Up</Link>
                    </h6>
                  </Form>
                ) : (
                  <Form className="mt-5">
                    <Form.Group className="form-group text-start">
                      <h6 className="form-label fw-bold">
                        Sign InVerification Code
                      </h6>
                      <div className="d-flex justify-content-center w-100" onPaste={handlePaste}>
                        {verificationCode.map((code, index) => (
                          <React.Fragment key={index}>
                            {index === 3 && <span key={`dash-${index}`} style={{ alignSelf: "center", padding: "0 10px" }}>-</span>}
                            <Form.Control
                              key={index}
                              type="text"
                              className="form-control mb-0 mx-1"
                              maxLength="1"
                              value={code}
                              onChange={(e) => handleCodeChange(index, e.target.value)}
                              ref={(el) => inputRefs.current[index] = el}
                              style={{ width: "3rem", textAlign: "center", flex: "1" }}
                            />
                          </React.Fragment>
                        ))}
                      </div>
                    </Form.Group>
                    <Button
                      variant="primary"
                      type="button"
                      className="btn btn-primary mt-4 fw-semibold text-uppercase w-100"
                      onClick={() => handleVerifyCode(verificationCode.join(""))}
                    >
                      Verify Code
                    </Button>
                  </Form>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SignIn;
