// src/services/memberfy.js
import { ApolloClient, InMemoryCache, gql } from '@apollo/client';

// Configuração do cliente Apollo
const client = new ApolloClient({
  uri: 'https://memberfy.hasura.app/v1/graphql',
  cache: new InMemoryCache(),
  headers: {
//    'x-hasura-user-id': '<USER_ID>',  // Substitua <USER_ID> pelo ID do usuário logado
//    'x-hasura-role': '<ROLE>',        // Substitua <ROLE> pelo papel do usuário
  },
});

// Consulta para listar os canais disponíveis
export const fetchChannels = async () => {
  const { data } = await client.query({
    query: gql`
      query MyQuery {
        channels(order_by: {order: asc}) {
          name
          id
          visibility
        }
      }
    `,
  });

  return data.channels;
};