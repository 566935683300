// src/utilities/api.js
import axios from 'axios';

const API_URL = 'https://memberfy.hasura.app/v1/graphql';

export const signIn = async (email) => {
  const query = `
    mutation MyMutation {
      signIn(member: {email: "${email}"}) {
        message
        success
      }
    }
  `;

  try {
    const response = await axios.post(API_URL, { query }, {
      headers: {
        'Content-Type': 'application/json',
        'X-Hasura-Role': 'public'
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error in signIn:", error);
    throw error;
  }
};
