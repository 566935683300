// src/contexts/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const isAuthenticated = localStorage.getItem('isAuthenticated');
    return isAuthenticated === 'true'; // Converte para booleano
  });

  console.log(isAuthenticated);

  useEffect(() => {
    // Verifica se o token está armazenado no localStorage
    const token = localStorage.getItem('accessToken');
    if (token) {
      localStorage.setItem('isAuthenticated', 'true');
      setIsAuthenticated(true);
    }
  }, []);

  const login = (token) => {
    localStorage.setItem('accessToken', token);
    localStorage.setItem('isAuthenticated', 'true');
    setIsAuthenticated(true);
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.setItem('isAuthenticated', 'false');
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
